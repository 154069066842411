import React from "react"
import PropTypes from "prop-types"
import Button from "../../../components/button"

class AboutComponent extends React.Component {
    render() {
        const {about} = this.props
        if (!about) return null
        return (
            <div id="about">
                <div className="container">
                    <div>
                        <h1>Mr.2HandStore</h1>
                        <p>{about.description}</p>
                    </div>
                    <Button className="primary" to="/about">
                        เกี่ยวกับเรา
                    </Button>
                </div>
            </div>
        )
    }
}

AboutComponent.propTypes = {
    about: PropTypes.object.isRequired
}

export default AboutComponent
