import React from "react"
import Helmet from "react-helmet"
import {Breadcrumb} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import {get} from "../../modules/axios"

class AboutPage extends React.Component {
    state = {data: {}}
    componentWillMount = () => {
        get("about.php", {}, (res) => {
            if (res) {
                this.setState({data: res})
            }
        })
    }
    render() {
        const {data} = this.state
        if (!data) return null
        return (
            <div id="about-page" className="page">
                <Helmet>
                    <title>เกี่ยวกับเรา -  Mr.2HandStore</title>
                    <meta name="description" content={data.description} />
                </Helmet>
                <Heading name="เกี่ยวกับเรา" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>เกี่ยวกับเรา</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="detail">
                        <div dangerouslySetInnerHTML={{__html: data.detail}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutPage
