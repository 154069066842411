import React from "react"
import {Form, Input, Button, message} from "antd"
import Icon from "../../icon"
import {imgSrc, getNumber} from "../../../modules"
import {post} from "../../../modules/axios"

class FooterComponent extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault()
        const {form} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                post("subscribe.php", values, (res) => {
                    if (res && typeof res === "object") {
                        if (res.status === "1") {
                            message.success(res.message, 6)
                        } else {
                            message.warning(res.message)
                        }
                    } else {
                        message.error("Error !")
                    }
                    form.resetFields()
                })
            }
        })
    }

    render() {
        const {form, info} = this.props
        const {getFieldDecorator} = form
        return (
            <footer>
                <div className="container">
                    <div>
                        <div>
                            <h1>{info.description}</h1>
                            <p>{info.address}</p>
                            <p className="phone">
                                <Icon type="icon-tel02" />
                                <a href={"tel:" + getNumber(info.tel)}>{info.tel}</a>
                            </p>
                        </div>
                        <div>
                            <h2>
                                <img src={imgSrc(info.logo)} alt={info.name} />
                            </h2>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item label="ติดตามข่าวสาร">
                                    {getFieldDecorator("subscribe_email", {
                                        rules: [{type: "email", message: "The input is not valid email!"}, {required: true, message: "Please input your email address!"}]
                                    })(<Input placeholder="Email address" />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Subscribe
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                            <h3>ติดตามเราได้ที่...</h3>
                            <div>
                                {info.line && (
                                    <React.Fragment>
                                        <img src={imgSrc("line.png", false)} alt="Line @" />
                                        <div>
                                            <p>
                                                Line ID: <a href={info.line_link}>{info.line}</a>
                                            </p>
                                            <p>
                                                Line QR :
                                                <a href={info.line_link} rel="nofollow" target="blank">
                                                    {info.line_link}
                                                </a>
                                            </p>
                                        </div>
                                    </React.Fragment>
                                )}
                                {info.fb && (
                                    <React.Fragment>
                                        <img src={imgSrc("fb.png", false)} alt="Facebook" />
                                        <div>
                                            <p>
                                                Facebook :
                                                <a href="/" rel="nofollow">
                                                    Mr.2Handstore
                                                </a>
                                            </p>
                                        </div>
                                    </React.Fragment>
                                )}
                                {info.ig && (
                                    <React.Fragment>
                                        <img src={imgSrc("ig.png", false)} alt="Instagram" />
                                        <div>
                                            <p>
                                                Instagram :
                                                <a href="/" rel="nofollow">
                                                    Mr.2Handstore
                                                </a>
                                            </p>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <p>
                    <a href="http://mr2handstore.com">mr2handstore.com</a> 2019 @ All Rights Reserved.
                </p>
            </footer>
        )
    }
}

export default Form.create({name: "subscribe"})(FooterComponent)
