import axios from "axios"
import qs from "qs"
import {apiUrl} from "./"
import {message} from "antd"

export const hasData = (data) => {
    if (data && typeof data === "object" && data.data !== "undefined" && data.status === 200) {
        return data.data
    }
    return false
}

export const get = (uri, data, callback) => {
    if (typeof data !== "object") return message.error("Invalid data")
    axios
        .get(apiUrl(uri), {
            params: data
        })
        .then(function(res) {
            callback(hasData(res))
        })
        .catch(function(err) {
            message.error("Error!!!, " + err)
            callback(false)
        })
}

export const post = (uri, data, callback) => {
    if (typeof data !== "object") return message.error("Invalid data")
    axios({
        method: "post",
        url: apiUrl(uri),
        data: qs.stringify(data),
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        }
    })
        .then((res) => {
            callback(hasData(res))
        })
        .catch((err) => {
            message.error("Error!!!, " + err)
            callback(false)
        })
}
