import React from "react"
import Helmet from "react-helmet"
import {Breadcrumb, Pagination} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import Item from "../../components/article/item"
import {get} from "../../modules/axios"


class ArticlesPage extends React.Component {
    state = {articles: [], loading: true, page: 1, pageSize: 15}

    componentWillMount = () => {
        get("articles.php", {}, (res) => {
            if (res && typeof res === "object") {
                this.setState({loading: false, articles: res})
            }
        })
    }

    handlePageChange = (page) => {
        this.setState({page: page})
    }

    render() {
        const {articles, loading, page, pageSize} = this.state
        if (loading) return null
        let filteredArticles = articles

        const min = (page - 1) * pageSize
        const max = page * pageSize
        const total = filteredArticles.length

        return (
            <div id="articles-page" className="page">
                <Helmet>
                    <title>บทความน่าสนใจ - Mr.2HandStore</title>
                </Helmet>
                <Heading name="บทความน่าสนใจ" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>บทความน่าสนใจ</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="articles">
                        {articles.map((value, key) => {
                            return key >= min && key < max && <Item item={value} key={"article" + key} />
                        })}
                    </div>
                    <div className="wrap-pagination">
                        <Pagination size="small" total={total} defaultPageSize={pageSize} current={page} onChange={this.handlePageChange} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ArticlesPage
