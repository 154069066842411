import React from "react"
import Helmet from "react-helmet"
import {Breadcrumb, Tabs, Radio, Pagination} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import Item from "../../components/item/item"
import {get} from "../../modules/axios"

const TabPane = Tabs.TabPane

class ProductsPage extends React.Component {
    state = {
        size: window.localStorage.getItem("size") || "ALL",
        sizes: [],
        items: [],
        categories: [],
        loading: true,
        categories_id: false,
        page: 1,
        pageSize: 20
    }
    handleChange = (categories_id) => {
        this.setState({categories_id: categories_id === "all" ? false : categories_id, page: 1})
    }
    handleSizeChange = (e) => {
        this.setState({size: e.target.value, page: 1})
        window.localStorage.setItem("size", e.target.value)
    }
    handlePageChange = (page) => {
        this.setState({page: page})
    }
    componentWillMount = () => {
        const {match} = this.props
        const {id} = match.params
        get("items.php", {}, (res) => {
            if (res && res.products && res.sizes && res.categories) {
                this.setState({items: res.products, sizes: res.sizes, categories: res.categories, loading: false, categories_id: id})
            }
        })
    }

    render() {
        const {size, categories, items, loading, categories_id, sizes, pageSize, page} = this.state

        let filteredItems = items
        if (loading) return null

        if (categories_id) {
            filteredItems = items.filter((item) => item.categories_id === categories_id)
        }

        if (size !== "ALL" && categories_id !== "1") {
            filteredItems = filteredItems.filter((item) => {
                return (item.size && item.size.toUpperCase() === size.toUpperCase()) || item.categories_id === "1"
            })
        }

        const min = (page - 1) * pageSize
        const max = page * pageSize
        const total = filteredItems.length

        return (
            <div id="products-page" className="page">
                <Helmet>
                    <title>สินค้า - Mr.2HandStore</title>
                    <meta name="description" content="เสื้อเชิ้ตลายสก็อตมือสองคัดเกรด แขนยาว/แขนสั้น เดรสสั้น มีท้ังเสื้อผู้หญิง,เสื้อผู้ชาย, และเสื้อผ้าเด็ก มีทั้งขายปลีกและขายส่ง" />
                </Helmet>
                <Heading name="สินค้า" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item href="/#/products">สินค้า</Breadcrumb.Item>
                        <Breadcrumb.Item>ทั้งหมด</Breadcrumb.Item>
                    </Breadcrumb>
                    <Tabs defaultActiveKey={categories_id || "all"} onChange={this.handleChange}>
                        <TabPane tab="ทั้งหมด" key="all">
                            <div className="wrap-size">
                                <Radio.Group value={size} onChange={this.handleSizeChange}>
                                    <Radio.Button value="ALL">All size</Radio.Button>
                                    {sizes.map((value, key) => {
                                        return (
                                            <Radio.Button value={value.name.toUpperCase()} key={"size_all" + key}>
                                                {value.name}
                                            </Radio.Button>
                                        )
                                    })}
                                </Radio.Group>
                            </div>
                            <div className="items">
                                {filteredItems.map((value, key) => {
                                    return key >= min && key < max && <Item item={value} key={"item" + key} />
                                })}
                            </div>
                            <div className="wrap-pagination">
                                <Pagination size="small" total={total} defaultPageSize={pageSize} current={page} onChange={this.handlePageChange} />
                            </div>
                        </TabPane>
                        {categories.map((value) => {
                            return (
                                <TabPane tab={value.name_line1} key={value.id}>
                                    {value.id !== "1" && (
                                        <div className="wrap-size">
                                            <Radio.Group value={size} onChange={this.handleSizeChange}>
                                                <Radio.Button value="ALL">All size</Radio.Button>
                                                {sizes.map((value, key) => {
                                                    return (
                                                        <Radio.Button value={value.name.toUpperCase()} key={"size" + key}>
                                                            {value.name}
                                                        </Radio.Button>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </div>
                                    )}
                                    {total > 0 && (
                                        <div className="items">
                                            {filteredItems.map((item, key) => {
                                                return key >= min && key < max && <Item item={item} key={"item" + key} />
                                            })}
                                        </div>
                                    )}
                                    {total === 0 && <p className="no-item-found">ไม่พบรายการสินค้า</p>}
                                    <div className="wrap-pagination">
                                        <Pagination size="small" total={total} defaultPageSize={pageSize} current={page} onChange={this.handlePageChange} />
                                    </div>
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default ProductsPage
