import React from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import Item from "./item"

class ItemsComponent extends React.Component {
    render() {
        const {name, items} = this.props
        if (!items) return null
        return (
            <div id="items">
                <h2 className="heading">{name}</h2>
                <div className="container">
                    <div className="items">
                        {items.map((value, key) => {
                            return <Item item={value} key={"item" + key} />
                        })}
                    </div>
                    <div className="wrap-viewmore">
                        <Link to="/products">สินค้าเพิ่มเติม</Link>
                    </div>
                </div>
            </div>
        )
    }
}

ItemsComponent.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array
}

export default ItemsComponent
