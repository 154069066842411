import React from "react"
import Helmet from "react-helmet"
import {Breadcrumb} from "antd"
import moment from "moment"
import Icon from "../../components/icon"
import Article from "../../components/article/item"
import {get} from "../../modules/axios"

class ArticleViewPage extends React.Component {
    state = {article: {}, loading: true}

    componentWillMount = () => {
        const {match, history} = this.props
        const {id} = match.params
        if (id) {
            get("article.php", {id: id}, (res) => {
                if (res && res.id) {
                    this.setState({article: res, loading: false})
                } else {
                    history.push("/articles")
                }
            })
        } else {
            history.push("/articles")
        }
    }

    render() {
        const {article, loading} = this.state
        if (loading) return null
        return (
            <div id="article-view-page" className="page">
                <Helmet>
                    <title>{article.name} - Mr.2HandStore</title>
                    <meta name="description" content={article.description} />
                </Helmet>
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item href="/#/articles">บทความน่าสนใจ</Breadcrumb.Item>
                        <Breadcrumb.Item>{article.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h1>{article.name}</h1>
                    <p>{moment(article.dates).format("LL")}</p>
                    <div className="detail">
                        <div dangerouslySetInnerHTML={{__html: article.detail}} />
                    </div>
                    <div id="article-similar">
                        <h2>บทความอื่ีนๆ ที่เกี่ยวข้อง</h2>
                        <div className="articles">
                            {article.similars.map((value, key) => {
                                return <Article item={value} key={"similar-article" + key} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArticleViewPage
