import React from "react"
import {Form, Icon, Input, Button, message} from "antd"
import {rules} from "../../modules"
import {post} from "../../modules/axios"

class LoginComponent extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault()
        const {form} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                post("login.php", values, (res) => {
                    if (res && res.status === "1") {
                        form.resetFields()
                        window.localStorage.setItem("token", res.data.token)
                        window.localStorage.setItem("email", res.data.email)
                        window.localStorage.setItem("name", res.data.name)
                        message.success(res.message)
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    } else if (res && res.status === "0") {
                        message.error(res.message)
                    }
                })
            }
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form
        return (
            <div id="login">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label="อีเมล">{getFieldDecorator("email", rules.email)(<Input prefix={<Icon type="mail" maxLength={100} />} placeholder="Email" />)}</Form.Item>
                    <Form.Item label="รหัสผ่าน">{getFieldDecorator("password", rules.password)(<Input minLength={4} maxLength={15} prefix={<Icon type="lock" />} type="password" placeholder="Password" />)}</Form.Item>
                    <div className="wrap-login">
                        <Button type="primary" htmlType="submit">
                            เข้าสู่ระบบ
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Form.create({name: "login"})(LoginComponent)
