import React from "react"
// import ReCAPTCHA from "react-google-recaptcha"
import Helmet from "react-helmet"
import queryString from "query-string"
import {Breadcrumb, Tabs, Form, DatePicker, Button, Input, InputNumber, message} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import {apiUrl} from "../../modules"
import {get, post} from "../../modules/axios"

const TabPane = Tabs.TabPane

// const paymentRecapcha = React.createRef()

class PaymentPage extends React.Component {
    state = {data: {}, loading: false}

    handleSubmit = (e) => {
        e.preventDefault()
        const {form} = this.props

        const files = document.querySelector("[type=file]").files
        const formData = new FormData()
        if (!files[0]) return alert("กรุณาอัปโหลดหลักฐานการโอนเงิน")
        formData.append("files", files[0])

        form.validateFields((err, values) => {
            if (!err) {
                formData.append("orderId", values.orderId)
                let data = {...values, dates: values.slip_date.format("YYYY-MM-DD")}
                delete data.slip_date
                this.setState({loading: true})
                fetch(apiUrl("upload.php"), {
                    method: "POST",
                    body: formData
                })
                    .then((res) => res.json())
                    .then((response) => {
                        if (response.status === "1") {
                            post("receive.php", {...data, slip: response.filename}, (res) => {
                                if (res.status === "1") {
                                    // message.success(res.message, 7)
                                    alert("ได้รับแจ้งการชำระเงินของท่านเรียบร้อยแล้ว ระบบกำลังตรวจสอบรายการ หากมีข้อสงสัย โทร.062-887-8987");
                                    form.resetFields()
                                } else {
                                    alert(res.message);
                                    // message.warning(res.message)
                                }
                                // paymentRecapcha.current.reset()
                                this.setState({loading: false})
                            })
                        } else {
                            alert(response.message);
                            // message.warning(response.message, 7)
                            // paymentRecapcha.current.reset()
                            this.setState({loading: false})
                        }
                    })
            }
        })
    }
    // onChange = (value) => {
    //     this.setState({recapcha: value})
    // }

    componentWillMount = () => {
        get("payment.php", {}, (res) => {
            if (res && res.id) {
                this.setState({data: res})
            }
        })
    }

    componentDidMount = () => {
        const {history} = this.props
        const {search} = history.location
        if (search) {
            const s = queryString.parse(search)
            this.props.form.setFieldsValue({orderId: s.id})
        }
    }

    render() {
        const {data, loading} = this.state
        const {form} = this.props
        const {getFieldDecorator} = form
        const config = {
            rules: [{required: true, message: "This field is required"}]
        }
        return (
            <div id="payment-page" className="page">
                <Helmet>
                    <title>วิธีสั่งซื้อ/แจ้งชำระเงิน - Mr.2HandStore</title>
                    <meta name="description" content={data.description} />
                </Helmet>
                <Heading name="วิธีสั่งซื้อ/แจ้งชำระเงิน" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>วิธีสั่งซื้อ/แจ้งชำระเงิน</Breadcrumb.Item>
                    </Breadcrumb>
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="วิธีสั่งซื้อสินค้า" key="1" forceRender={true}>
                            <div className="detail">
                                <div dangerouslySetInnerHTML={{__html: data.detail}} />
                            </div>
                        </TabPane>
                        <TabPane tab="แจ้งการชำระเงิน" key="2" forceRender={true}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item label="เลขที่ใบสั่งซื้อ">{getFieldDecorator("orderId", config)(<Input />)}
                                <p className="alert-message">** รบกวนคุณลูกค้าคัดลอกเลขที่ใบสั่งซื้อไว้ กรณีไม่สามารถแจ้งโอนเงินได้กรุณาทำรายการใหม่อีกครั้ง</p>
                                </Form.Item>
                                <Form.Item label="ธนาคารต้นทางที่โอน">{getFieldDecorator("bank", config)(<Input />)}</Form.Item>
                                <Form.Item label="วันที่ชำระเงิน">{getFieldDecorator("slip_date", config)(<DatePicker />)}</Form.Item>
                                <Form.Item label="จำนวนเงิน">
                                    {getFieldDecorator("price", config)(<InputNumber min={1} />)}
                                    <span className="ant-form-text"> ระบุจำนวนเงิน (฿)</span>
                                </Form.Item>
                                <Form.Item label="หลักฐานการโอนเงิน">{getFieldDecorator("slip", {rules: [{required: true, message: "Please upload resume"}]})(<Input type="file" accept=".pdf,.jpg,.jpeg,.JPG,.png" />)}</Form.Item>
                                <Form.Item label="&nbsp;">
                                    {/* <ReCAPTCHA ref={paymentRecapcha} sitekey={recaptchaKey()} onChange={this.onChange} /> */}
                                    <div className="wrap-submit">
                                        {!loading && (
                                            <Button htmlType="submit">
                                                <Icon type="icon-check1" />
                                                <span>แจ้งการชำระเงิน</span>
                                            </Button>
                                        )}
                                        {loading && (
                                            <Button htmlType="button">
                                                <span>กรุณารอสักครู่...</span>
                                            </Button>
                                        )}
                                    </div>
                                </Form.Item>
                            </Form>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Form.create({name: "payment"})(PaymentPage)
