import React from "react"
import {Link} from "react-router-dom"
import {imgSrc} from "../../../modules"

class CategoryComponent extends React.Component {
    render() {
        const {categories} = this.props
        if (!categories) return null
        return (
            <div id="category">
                <h2 className="heading">แคตตาล็อค</h2>
                <div>
                    {categories.map((value, key) => {
                        return (
                            <div key={"category" + key}>
                                <Link to={"products/" + value.id}>
                                    <img src={imgSrc(value.image)} alt={value.name_line1} />
                                    <h3>
                                        <span>
                                            {value.name_line1}
                                            <span>{value.name_line2}</span>
                                        </span>
                                    </h3>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default CategoryComponent
