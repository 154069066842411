import React from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import numeral from "numeral"
import Icon from "../icon"
import {imgSrc} from "../../modules"

class ItemComponent extends React.Component {
    render() {
        const {item} = this.props
        return (
            <div className="item">
                <div className={item.categories_id === "1" ? "wholesale" : ""}>
                    <img src={imgSrc(item.image)} alt={item.image} />
                    <div>
                        <p>{numeral(item.price).format("0,0")} ฿</p>
                        <p>
                            {item.categories_id === "1" ? "" : "Code : "}
                            {item.code}
                        </p>
                        {item.categories_id !== "1" && <p>Size : {item.size}</p>}
                        {item.categories_id === "1" && <p>Size : คละไซส์</p>}
                        <p className="item-description">
                            <span dangerouslySetInnerHTML={{__html: item.description}} />
                        </p>
                        <p className="item-brand">Brand : {item.brand}</p>
                        <div>
                            <Link to={"/product/" + item.id} className="view">
                                <Icon type="icon-cart-white" />
                                <span>สั่งซื้อสินค้า</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ItemComponent.propTypes = {
    item: PropTypes.shape({
        categories_id: PropTypes.string,
        image: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        code: PropTypes.string,
        size: PropTypes.string,
        brand: PropTypes.string
    }).isRequired
}

export default ItemComponent
