import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"

class ButtonComponent extends React.Component {
    render() {
        const {to, children, className} = this.props
        return (
            <Link to={to} className={className}>
                {children}
            </Link>
        )
    }
}

ButtonComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default ButtonComponent
