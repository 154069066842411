import React from "react"
import Helmet from "react-helmet"
import {Table, Button, InputNumber} from "antd"
import numeral from "numeral"
import {imgSrc} from "../../modules"
import Icon from "../../components/icon"
import Item from "../../components/item/item"
import ButtonLink from "../../components/button"
import {post} from "../../modules/axios"

class CartPage extends React.Component {
    state = {items: []}

    setQty = (record, num) => {
        let items = window.localStorage.getItem("cart")
        if (items) {
            items = JSON.parse(items)
            items.forEach((value, key) => {
                if (record.id === value.id) {
                    items[key].qty = num * 1
                }
            })
            this.setState({items: items})
            window.localStorage.setItem("cart", JSON.stringify(items))
        }
    }

    deleteItem = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            const {items} = this.state
            items.forEach((value, key) => {
                if (value.id === id) {
                    items.splice(key, 1)
                }
            })
            window.localStorage.setItem("cart", JSON.stringify(items))
            if (items.length > 0) {
                window.location = "/#/cart?n=" + items.length
                window.location.reload();
            } else {
                window.location = "/#/cart"
                window.location.reload();
            }
        }
    }

    clearItem = () => {
        if (window.confirm("Are you sure you want to delete all items in the cart?")) {
            window.localStorage.removeItem("cart")
            window.location = "/#/cart"
            window.location.reload();
        }
    }

    columns = () => [
        {
            title: "สินค้า",
            dataIndex: "code",
            key: "code",
            render: (code, record) => {
                return (
                    <div className="item-detail">
                        <img src={imgSrc(record.image)} alt={code} />
                        <div>
                            <h3>Code : {record.code}</h3>
                            <p>Size : {record.categories_id === "1" ? "คละไซส์" : record.size.name}</p>
                            <p>{record.width}</p>
                            <p>Brand : {record.brand.name}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            title: "ราคา",
            dataIndex: "price",
            key: "price",
            render: (price) => {
                return <span>฿ {numeral(price).format("0,0")}</span>
            }
        },
        {
            title: "จำนวน",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity, record) => {
                const qty = record.qty ? record.qty * 1 : 1
                return quantity * 1 > 1 ? <InputNumber onChange={(num) => this.setQty(record, num)} defaultValue={qty} max={quantity * 1} min={1} /> : 1
            }
        },
        {
            title: "ราคารวม",
            dataIndex: "price",
            key: "net",
            render: (price, record) => {
                const qty = record.qty ? record.qty * 1 : 1
                return <div>฿ {numeral(price * qty).format("0,0")}</div>
            }
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (id) => {
                return (
                    <button type="button" className="btn-delete-item" onClick={() => this.deleteItem(id)}>
                        <Icon type="icon-bin" classNames="delete-item" />
                    </button>
                )
            }
        }
    ]

    componentDidMount = () => {
        const {match, history} = this.props
        const {id} = match.params
        let items = window.localStorage.getItem("cart")
        let hasItem = false
        let cart = []
        items = items && items !== "" ? JSON.parse(items) : []
        items.forEach((value) => {
            if (value.id) {
                cart.push(value.id)
            }
            if (value.id === id) {
                hasItem = true
            }
        })
        if (!hasItem && id) {
            cart.push(id)
        }
        if (cart && cart.length > 0) {
            post("cart.php", {items: cart.join(",")}, (res) => {
                if (res && typeof res === "object") {
                    let itemWithQty = res
                    itemWithQty.forEach((value, key) => {
                        items.forEach((row) => {
                            if (value.id === row.id) {
                                itemWithQty[key].qty = row.qty
                            }
                        })
                    })
                    this.setState({items: itemWithQty})
                    window.localStorage.setItem("cart", JSON.stringify(itemWithQty))
                    if (id) {
                        history.push("/cart/" + id + "?n=" + itemWithQty.length)
                    }
                }
            })
        }
    }
    render() {
        const {items} = this.state
        let total = 0
        items.forEach((value) => {
            const num = value.price * value.qty
            total = total + num
        })
        let visited = window.localStorage.getItem("visited")
        if (visited) {
            visited = JSON.parse(visited)
            visited = visited.reverse()
        }
        return (
            <div id="cart-page" className="page">
                <Helmet>
                    <title>ตะกร้าสินค้าของฉัน - Mr.2HandStore</title>
                </Helmet>
                <div className="container">
                    <h1>ตะกร้าสินค้าของฉัน</h1>
                    <div id="cart-detail">
                        {items && items.length > 0 ? (
                            <div>
                                <Table dataSource={items} columns={this.columns()} pagination={false} rowKey={(rec) => rec.id} />

                                <div id="wrap-clear-button">
                                    <Button htmlType="button" onClick={this.clearItem}>
                                        <Icon type="icon-LC_icon_close_fill_circle" />
                                        <span>ยกเลิกการสั่งซื้อ</span>
                                    </Button>
                                </div>

                                <div id="wrap-total">
                                    <h2>ยอดรวมสินค้าในตะกร้า</h2>
                                    <p>
                                        <span>ยอดรวมทั้งสิ้น</span>
                                        <span>฿ {numeral(total).format("0,0.00")}</span>
                                    </p>
                                    <div>
                                        <ButtonLink to="/products" className="primary">
                                            ซื้อของต่อ
                                        </ButtonLink>
                                        <ButtonLink to="/confirm" className="primary">
                                            <React.Fragment>
                                                <span>ดำเนินการชำระเงิน</span>
                                                <Icon type="icon-angleright" />
                                            </React.Fragment>
                                        </ButtonLink>
                                    </div>
                                </div>
                                <p className="note">จัดส่งสินค้าถึงขนส่งภายใน 2 วันทำการ นับตั้งแต่วันที่ทำรายการเสร็จสิ้น</p>
                            </div>
                        ) : (
                            <p id="cart-not-found">ไม่พบรายการสินค้าในตะกร้า</p>
                        )}
                        {visited && (
                            <div>
                                <h2>สินค้าที่เคยเยี่ยมชม</h2>
                                <div className="items col1">
                                    {visited.map((value, key) => {
                                        return key < 2 && <Item item={value} key={"visited" + key} />
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CartPage
