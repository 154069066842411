import React from "react"
import Helmet from "react-helmet"
import {Link} from "react-router-dom"
import numeral from "numeral"
import {Select, Form, Input, Checkbox, Table, Button, message} from "antd"
import {rules, imgSrc} from "../../modules"
import {get, post} from "../../modules/axios"
import Icon from "../../components/icon"

const Option = Select.Option

const columns = [
    {
        title: "สินค้า",
        dataIndex: "code",
        key: "code",
        render: (code, record) => {
            return (
                <div className="item-detail">
                    <img src={imgSrc(record.image)} alt={code} />
                    <div>
                        <h3>รหัส {record.code}</h3>
                    </div>
                </div>
            )
        }
    },
    {
        title: "จำนวน",
        dataIndex: "qty",
        key: "qty",
        render: (qty) => {
            return numeral(qty).format("0,0")
        }
    },
    {
        title: "ราคา",
        dataIndex: "price",
        key: "price",
        render: (price, record) => {
            return <div>฿ {numeral(price * record.qty).format("0,0")}</div>
        }
    }
]

class ConfirmPage extends React.Component {
    state = {data: {}, amphurs: false, tambons: false, shipping: "register", loading: false}
    handleSubmit = (e) => {
        e.preventDefault()
        const {form, member, history} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                let items = window.localStorage.getItem("cart")
                this.setState({loading: true})
                post("transaction.php", {...values, token: member.token, items: items}, (res) => {
                    this.setState({loading: false})
                    if (res && res.status === "1") {
                        window.localStorage.setItem("transaction", JSON.stringify(res.transaction))
                        window.localStorage.setItem("transaction_list", JSON.stringify(res.list))
                        history.push("/success?n=0")
                    } else if (res) {
                        message.error(res.message)
                    }
                })
            }
        })
    }
    componentDidMount = () => {
        get("confirm.php", {}, (res) => {
            if (res && res.id) {
                this.setState({data: res})
                setTimeout(() => {
                    this.useMemberAddress(false)
                }, 1000)
            }
        })
    }
    useMemberAddress = (e) => {
        // console.log('e', e.target.checked)
        const {form, member} = this.props
        if (e && e.target.checked === false) {
            form.resetFields()
        } else if (member && member.email && member.token) {
            form.setFieldsValue({email: member.email, name: member.name, tel: member.tel, address: member.address, province: member.province_id, amphur: member.amphur_id, tambon: member.tambon_id, zipcode: member.zipcode, useMemberAddress: true})
        }
    }

    getAmphurs = (provinces_id) => {
        get("amphurs.php", {provinces_id}, (res) => {
            if (res && typeof res === "object") {
                this.setState({amphurs: res, tambons: []})
                this.props.form.setFields({amphur: "", tambon: ""})
            }
        })
    }
    getTambons = (amphurs_id) => {
        get("tambons.php", {amphurs_id}, (res) => {
            if (res && typeof res === "object") {
                this.setState({tambons: res})
                this.props.form.setFields({tambon: ""})
            }
        })
    }

    handleShipping = (e) => {
        this.setState({shipping: e})
    }

    render() {
        const {data, amphurs, tambons, shipping, loading} = this.state
        const {form, member, thai} = this.props
        const {getFieldDecorator} = form
        let items = window.localStorage.getItem("cart")
        let total = 0
        let shippingPrice = 0
        let itemsSplit = {items: [], packages: []}
        if (!items) return null
        items = JSON.parse(items)
        if (!items || typeof items !== "object" || items.length === 0 || !thai.provinces) return null

        items.forEach((value) => {
            const num = value.price * value.qty
            total = total + num
            if (value.categories_id === "1") {
                itemsSplit.packages.push(value)
            } else {
                itemsSplit.items.push(value)
            }
        })

        let amphurList = amphurs || []
        let tambonList = tambons || []
        if (thai.amphurs && amphurs === false) {
            amphurList = thai.amphurs
        }
        if (thai.tambons && tambons === false) {
            tambonList = thai.tambons
        }

        if (shipping === "ems") {
            if (itemsSplit.items.length === 1) {
                shippingPrice = 30
            } else if (itemsSplit.items.length > 1) {
                shippingPrice = (itemsSplit.items.length-1) * 10
                shippingPrice += 30
            }
        } else {
            shippingPrice = 0;
            // if (itemsSplit.items.length === 1) {
            //     shippingPrice = 30
            // } else if (itemsSplit.items.length > 1) {
            //     shippingPrice = itemsSplit.items.length * 15
            //     shippingPrice += 15
            // }
        }

        if (itemsSplit.packages.length > 0) {
            let shippingPrice2 = 0
            for (let i = 0; i < itemsSplit.packages.length; i++) {
                shippingPrice2 += itemsSplit.packages[i].qty * 200
            }
            shippingPrice = shippingPrice + shippingPrice2
        }

        return (
            <div id="confirm-page" className="page">
                <Helmet>
                    <title>สั่งซื้อสินค้า - Mr.2HandStore</title>
                </Helmet>
                <Form onSubmit={this.handleSubmit}>
                    <div className="container">
                        <h1>สั่งซื้อสินค้า</h1>
                        <div id="confirm-detail">
                            <div id="confirm-location">
                                <div>
                                    <h2>ข้อมูลจัดส่ง</h2>
                                    <Form.Item label="อีเมล">
                                        {getFieldDecorator("email", {
                                            rules: [{type: "email", required: false, message: "This field is required"}]
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="ชื่อ-สกุล">{getFieldDecorator("name", rules.text)(<Input />)}</Form.Item>
                                    <Form.Item label="เบอร์โทร">{getFieldDecorator("tel", rules.text)(<Input />)}</Form.Item>
                                    <Form.Item label="ที่อยู่">{getFieldDecorator("address", rules.text)(<Input.TextArea />)}</Form.Item>
                                    <Form.Item label="จังหวัด">
                                        {getFieldDecorator("province", rules.select)(
                                            <Select onChange={this.getAmphurs}>
                                                <Option value="">เลือกจังหวัด</Option>
                                                {thai.provinces.map((value, key) => {
                                                    return (
                                                        <Option value={value.id} key={"province" + key}>
                                                            {value.name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="อำเภอ">
                                        {getFieldDecorator("amphur", rules.select)(
                                            <Select onChange={this.getTambons}>
                                                <Option value="">เลือกอำเภอ</Option>
                                                {amphurList.map((value, key) => {
                                                    return (
                                                        <Option value={value.id} key={"amphur" + key}>
                                                            {value.name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="ตำบล">
                                        {getFieldDecorator("tambon")(
                                            <Select>
                                                <Option value="">เลือกตำบล</Option>
                                                {tambonList.map((value, key) => {
                                                    return (
                                                        <Option value={value.id} key={"tambon" + key}>
                                                            {value.name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="รหัสไปรษณีย์">{getFieldDecorator("zipcode", rules.text)(<Input />)}</Form.Item>
                                    <p className="note">ที่อยู่สำหรับจัดส่งสินค้า</p>
                                    {member.token && (
                                        <Form.Item>
                                            {getFieldDecorator("useMemberAddress", {
                                                valuePropName: "checked"
                                            })(<Checkbox onChange={this.useMemberAddress}>ใช้ที่อยู่เดียวกับที่อยู่ของสมาชิก</Checkbox>)}
                                        </Form.Item>
                                    )}
                                </div>
                            </div>
                            <div id="confirm-bank">
                                <div>
                                    <div>
                                        <h2>การชำระเงิน</h2>
                                        <p>
                                            <b>
                                                ช่องทางการชำระเงิน <em>*</em>
                                            </b>
                                        </p>
                                        <div>
                                            <div dangerouslySetInnerHTML={{__html: data.payment_detail}} />
                                        </div>
                                    </div>
                                    <div>
                                        <h2>การจัดส่งสินค้า</h2>
                                        <div>
                                            <div dangerouslySetInnerHTML={{__html: data.shipping_detail}} />
                                        </div>
                                        {itemsSplit.items.length > 0 && (
                                            <React.Fragment>
                                                <p id="shipping-channel">
                                                    <b>ช่องทางการจัดส่ง (เฉพาะสินค้าขายปลีก)</b>
                                                </p>
                                                <Form.Item label={false}>
                                                    {getFieldDecorator("shipping-channel", {initialValue: shipping, ...rules.select})(
                                                        <Select onChange={this.handleShipping}>
                                                            <Option value="ems">เก็บเงินปลายทาง</Option>
                                                            <Option value="register">ฟรีจัดส่ง!!(ขนส่งเอกชน)</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div id="confirm-summary">
                                <div>
                                    <h2>สรุปรายการสั่งซื้อ</h2>
                                    <p>รายละเอียดการสั่งซื้อสินค้า</p>
                                    <Table dataSource={items} columns={columns} pagination={false} rowKey={(rec) => rec.id} />
                                    <p id="edit-cart">
                                        <Link to="/cart">แก้ไขตะกร้าสินค้า</Link>
                                    </p>
                                    <div>
                                        <Form.Item label="ระบุหมายเหตุ (ถ้ามี)">{getFieldDecorator("note")(<Input.TextArea />)}</Form.Item>
                                    </div>
                                    <div id="summary">
                                        <p>
                                            <span>ยอดรวม</span>
                                            <span>฿ {numeral(total).format("0,0.00")}</span>
                                        </p>
                                        <p>
                                            <span>ค่าจัดส่ง</span>
                                            <span>฿ {numeral(shippingPrice).format("0,0.00")}</span>
                                        </p>
                                        <p>
                                            <span>ยอดรวมทั้งหมด</span>
                                            <span>฿ {numeral(total + shippingPrice).format("0,0.00")}</span>
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        {!loading ? (
                                            <Button type="primary" className="primary" htmlType="submit">
                                                <span>ยืนยันการซื้อ</span>
                                                <Icon type="icon-arrow-right" />
                                            </Button>
                                        ) : (
                                            <Button type="primary" className="primary" htmlType="button">
                                                <span>กรุณารอสักครู่...</span>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Form.create({name: "confirm"})(ConfirmPage)
