import React from "react"
import {Link} from "react-router-dom"
import Helmet from "react-helmet"
import numeral from "numeral"
import {Carousel, Breadcrumb} from "antd"
import {imgSrc} from "../../modules"
import Icon from "../../components/icon"
import Item from "../../components/item/item"
import {get} from "../../modules/axios"

class ProductPage extends React.Component {
    state = {item: {}, loading: true}

    getItemById = (id) => {
        const {history} = this.props
        if (id) {
            this.setState({loading: true})
            get("item.php", {id: id}, (res) => {
                if (res && res.id) {
                    this.setState({item: res, loading: false})
                    let items = window.localStorage.getItem("visited")
                    let hasItem = false
                    items = items ? JSON.parse(items) : []
                    items.forEach((value, key) => {
                        if (value.id === id) {
                            hasItem = true
                        }
                    })
                    if (!hasItem) {
                        delete res.detail
                        delete res.images
                        delete res.similars
                        res.size = res.size.name
                        res.category = res.category.name
                        res.brand = res.brand.name
                        items.push(res)
                    }
                    if (items.length > 4) {
                        items.splice(0, items.length - 4)
                    }
                    window.localStorage.setItem("visited", JSON.stringify(items))
                } else {
                    history.push("/products")
                }
            })
        } else {
            history.push("/products")
        }
    }

    componentWillMount = () => {
        const {match} = this.props
        const {id} = match.params
        this.getItemById(id);
    }
    
    componentWillReceiveProps = (nextProps) => {
        const {item} = this.state
        if(item.id && item.id !== nextProps.state){
            this.getItemById(nextProps.match.params.id);
        }
    }

    render() {
        const {item, loading} = this.state
        if (loading) return null
        return (
            <div id="item-page" className="page">
                <Helmet>
                    <title>{item.code} - Mr.2HandStore</title>
                    <meta name="description" content={item.description} />
                </Helmet>
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item href="/#/products">สินค้า</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/#/products/" + item.category.id}>{item.category.name_line1}</Breadcrumb.Item>
                        <Breadcrumb.Item>รหัสสินค้า {item.code}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        <div id="main-item">
                            <div>
                                <Carousel autoplay draggable>
                                    {item.images.map((value, key) => {
                                        return (
                                            <div key={"gallery" + key}>
                                                <img src={imgSrc(value.image)} alt={value.name} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <div>
                                <h1>รหัสสินค้า {item.code}</h1>
                                <p>
                                    <b>ยี่ห้อ :</b> {item.brand.name}
                                </p>
                                <p>
                                    <b>ขนาด :</b> {item.size.name}
                                </p>
                                <p>{item.description}</p>
                                <p className="qty">
                                    {item.quantity > 0 ? (
                                        <React.Fragment>จำนวนสินค้าที่เหลือ : {item.quantity > 100 ? <span>มากกว่า 100 รายการ</span> : <span>{numeral(item.quantity).format("0,0")}</span>}</React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <span id="out-of-stock">สินค้าหมด</span>
                                        </React.Fragment>
                                    )}
                                </p>
                                <p className="price">THB {numeral(item.price).format("0,0")}</p>
                                {item.quantity > 0 && (
                                    <div>
                                        <Link to={"/cart/" + item.id} className="view">
                                            <Icon type="icon-cart-white" />
                                            <span>สั่งซื้อสินค้า</span>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div id="item-detail">
                            <h2>รายละเอียดสินค้า</h2>
                            <div className="detail">
                                <div dangerouslySetInnerHTML={{__html: item.detail}} />
                            </div>
                        </div>
                        <div id="item-similar">
                            <h3>สินค้าที่เกี่ยวข้อง</h3>
                            <div className="items">
                                {item.similars.map((value, key) => {
                                    return <Item item={value} key={"item" + key} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductPage
