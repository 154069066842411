export const imgSrc = (image, upload = true) => {
    return upload ? "/img/upload/" + image : "/img/" + image
}

export const apiUrl = (uri = "") => {
    const {location} = window
    const apiLink = location.hostname === "localhost" ? "http://localhost:9000" : "https://" + location.hostname + "/server"
    return apiLink + "/" + uri
}

export const recaptchaKey = () => {
    const {location} = window
    return location.hostname === "localhost" ? "6Ldk8hkUAAAAADtU_HKXPKIdJUo_vOsBFz6oAhiK" : "6LdPIKAUAAAAAGIToWYOm-SGthKWdMMuuw1qzQ1x"
}

export const rules = {
    text: {rules: [{required: true, message: "This field is required"}]},
    password: {rules: [{required: true, message: "This field is required"}]},
    email: {
        rules: [{type: "email", required: true, message: "This field is required"}]
    },
    select: {
        rules: [{required: true, message: "This field is required"}]
    }
}

export const getNumber = (tel) => {
    if (tel) {
        return tel.replace(/-|\s|_|\(|\)/g, "")
    } else {
        return null
    }
}
