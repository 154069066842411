import React from "react"
import Helmet from "react-helmet"
import numeral from "numeral"
import {Tabs, Form, Select, Input, Button, Table, message} from "antd"
import Icon from "../../components/icon"
import {rules, imgSrc} from "../../modules"
import {post, get} from "../../modules/axios"

const TabPane = Tabs.TabPane
const {Option} = Select

const Search = Input.Search

const columns = [
    {
        title: "เลขที่ใบสั่งซื้อ",
        dataIndex: "orderId",
        key: "orderId"
    },
    {
        title: "ราคาสินค้ารวม",
        dataIndex: "total",
        key: "total",
        render: (total) => {
            return <span>{numeral(total).format("0,0")}</span>
        }
    },
    {
        title: "ค่าจัดส่ง",
        dataIndex: "shipping_price",
        key: "shipping_price",
        render: (shipping_price, rec) => {
            let shipping = rec.nimExpress === "1" ? "Nim express" : rec.shipping_type
            if (shipping === "Nim express") {
                let postOffice = false
                rec.list.forEach((value) => {
                    if (value.categories_id !== "1") {
                        postOffice = true
                    }
                })
                if(postOffice){
                    shipping += "/ไปรษณีย์ " + rec.shipping_type
                }
            }
            return (
                <span>
                    {numeral(shipping_price).format("0,0")} ({shipping})
                </span>
            )
        }
    },
    {
        title: "ราคาสุทธิ",
        dataIndex: "net",
        key: "net",
        render: (net) => {
            return numeral(net).format("0,0.00")
        }
    },
    {
        title: "สถานะการสั่งซื้อ",
        dataIndex: "status",
        key: "status",
        filters: [
            {
                text: "รอการชำระเงิน",
                value: "1"
            },
            {
                text: "แจ้งการชำระเงินแล้วรอการตรวจสอบ",
                value: "5"
            },
            {
                text: "อยู่ระหว่างการจัดส่ง",
                value: "3"
            },
            {
                text: "ส่งสินค้าแล้ว",
                value: "4"
            },
            {
                text: "ยกเลิก",
                value: "2"
            }
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: (status, record) => {
            let statusName = "-"
            if (status === "1") {
                statusName = "รอการชำระเงิน"
            } else if (status === "2") {
                statusName = "ยกเลิก"
            } else if (status === "3") {
                statusName = "อยู่ระหว่างการจัดส่ง"
            } else if (status === "4") {
                statusName = "ส่งสินค้าแล้ว"
            } else if (status === "5") {
                statusName = "แจ้งการชำระเงินแล้วรอการตรวจสอบ"
            }
            return statusName
        }
    },
    {
        title: "วันที่สั่งซื้อ",
        dataIndex: "created_date",
        key: "created_date"
    }
]

class ProfilePage extends React.Component {
    state = {amphurs: false, tambons: false, histories: []}
    handleSubmit = (e) => {
        e.preventDefault()
        const {form, member} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                post("profile.php", {...values, token: member.token, email: member.email}, (res) => {
                    if (res && res.status !== "1") {
                        message.error("ไม่สามารถบันทึกข้อมูลได้กรุณาลองใหม่อีกครั้ง")
                    }
                    window.location.reload()
                })
            }
        })
    }

    handleChangePassword = (e) => {
        e.preventDefault()
        const {form, member} = this.props
        form.validateFields((err, values) => {
            if (!values.repassword || !values.password) {
                return null
            }
            if (values.repassword !== values.password) {
                message.warning("Password and confirm password does not match")
                return null
            }

            post("changepass.php", {...values, token: member.token, email: member.email}, (res) => {
                if (res && res.status === "1") {
                    message.success(res.message)
                    form.setFieldsValue({password: "", repassword: "", oldpassword: ""})
                } else {
                    message.error(res.message)
                }
            })
        })
    }

    getAmphurs = (provinces_id) => {
        get("amphurs.php", {provinces_id}, (res) => {
            if (res && typeof res === "object") {
                this.setState({amphurs: res, tambons: []})
                this.props.form.setFields({amphur: "", tambon: ""})
            }
        })
    }
    getTambons = (amphurs_id) => {
        get("tambons.php", {amphurs_id}, (res) => {
            if (res && typeof res === "object") {
                this.setState({tambons: res})
                this.props.form.setFields({tambon: ""})
            }
        })
    }

    componentWillMount = () => {
        const {member} = this.props
        post("history.php", {email: member.email, token: member.token}, (res) => {
            if (res && typeof res === "object") {
                this.setState({histories: res})
            }
        })
    }

    expendItem = (record) => {
        const items = record.list.map((value, key) => {
            return (
                <div key={"history" + key} className="history-expend">
                    <img src={imgSrc(value.image)} alt={value.code} />
                    <div>
                        <p>สินค้า : {value.code}</p>
                        <p>จำนวน : {numeral(value.quantity).format("0,0")}</p>
                        <p>ราคาต่อชิ้น : {numeral(value.price).format("0,0.00")}</p>
                        <p>ราคาต่อรวม : {numeral(value.total).format("0,0.00")}</p>
                    </div>
                </div>
            )
        })
        return items
    }

    render() {
        const {tambons, amphurs, histories} = this.state
        const {form, member, thai} = this.props
        const {getFieldDecorator} = form
        if (!thai || !member) return null
        let amphurList = amphurs
        let tambonList = tambons

        if (thai.amphurs && amphurs === false) {
            amphurList = thai.amphurs
        }
        if (thai.tambons && tambons === false) {
            tambonList = thai.tambons
        }

        amphurList = typeof amphurList === "object" ? amphurList : []
        tambonList = typeof tambonList === "object" ? tambonList : []

        return (
            <div id="profile-page" className="page">
                <Helmet>
                    <title>โปรไฟล์ - Mr.2HandStore</title>
                    <meta name="description" content="Mr.2HandStore, 142/9 ถ.ประชาสัมพันธ์ ต.ช้างคลาน อ.เมือง จ.เชียงใหม่ 50100 โทร.062-887-8987 Line ID/IG : Mr.2HandStore" />
                </Helmet>
                <div className="container">
                    <div>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="ข้อมูลส่วนตัว" key="1">
                                <div className="profile">
                                    <Form onSubmit={this.handleSubmit}>
                                        <div>
                                            <Form.Item label="ชื่อ-สกุล">{getFieldDecorator("name", {...rules.text, initialValue: member.name})(<Input />)}</Form.Item>
                                            <Form.Item label="ที่อยู่">{getFieldDecorator("address", {...rules.text, initialValue: member.address})(<Input.TextArea />)}</Form.Item>
                                            <Form.Item label="จังหวัด">
                                                {getFieldDecorator("province", {initialValue: member.province_id && amphurs === false ? member.province_id : "", ...rules.select})(
                                                    <Select onChange={this.getAmphurs}>
                                                        <Option value="">เลือกจังหวัด</Option>
                                                        {thai.provinces.map((value, key) => {
                                                            return (
                                                                <Option value={value.id} key={"province" + key}>
                                                                    {value.name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="อำเภอ">
                                                {getFieldDecorator("amphur", {initialValue: member.amphur_id && amphurs === false ? member.amphur_id : "", ...rules.select})(
                                                    <Select onChange={this.getTambons}>
                                                        <Option value="">เลือกอำเภอ</Option>
                                                        {amphurList.map((value, key) => {
                                                            return (
                                                                <Option value={value.id} key={"amphur" + key}>
                                                                    {value.name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="ตำบล">
                                                {getFieldDecorator("tambon", {initialValue: member.tambon_id && tambons === false ? member.tambon_id : ""})(
                                                    <Select>
                                                        <Option value="">เลือกตำบล</Option>
                                                        {tambonList.map((value, key) => {
                                                            return (
                                                                <Option value={value.id} key={"tambon" + key}>
                                                                    {value.name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="รหัสไปรษณีย์">{getFieldDecorator("zipcode", {...rules.text, initialValue: member.zipcode})(<Input />)}</Form.Item>
                                            <Form.Item label="เบอร์โทร">{getFieldDecorator("tel", {initialValue: member.tel})(<Input placeholder="เว้นว่างได้" />)}</Form.Item>
                                            <div className="wrap-profile">
                                                <div>
                                                    <Button type="primary" htmlType="submit">
                                                        <span>บันทึกการแก้ไข</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </TabPane>
                            <TabPane tab="ประวัติการสั่งซื้อ" key="3">
                                <div id="history">
                                    <div className="text-center">
                                        <div id="search-profile">
                                            <label>ค้นหา</label>
                                            <Search placeholder="เลขที่ใบสั่งซื้อ" onSearch={(value) => console.log(value)} style={{width: 200}} />
                                        </div>
                                    </div>
                                    <Table bordered={true} dataSource={histories} columns={columns} expandedRowRender={this.expendItem} />
                                </div>
                            </TabPane>
                            <TabPane tab="เปลี่ยนรหัสผ่าน" key="2">
                                <div className="profile">
                                    <Form onSubmit={this.handleChangePassword}>
                                        <Form.Item label="รหัสผ่านเดิม">{getFieldDecorator("oldpassword")(<Input type="password" minLength={4} maxLength={15} prefix={<Icon type="icon-lock" />} placeholder="Old Password" />)}</Form.Item>
                                        <Form.Item label="รหัสผ่านใหม่">{getFieldDecorator("password")(<Input prefix={<Icon type="icon-lock" />} minLength={4} maxLength={15} type="password" placeholder="New Password" />)}</Form.Item>
                                        <Form.Item label="รหัสผ่านใหม่อีกครั้ง">{getFieldDecorator("repassword")(<Input prefix={<Icon type="icon-Refresh" />} minLength={4} maxLength={15} type="password" placeholder="Re-New Password" />)}</Form.Item>
                                        <div className="wrap-profile">
                                            <Button type="primary" htmlType="submit">
                                                <span>บันทึกการแก้ไข</span>
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({name: "profile"})(ProfilePage)
