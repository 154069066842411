import React from "react"
import ReactDOM from "react-dom"
import {HashRouter} from "react-router-dom"
import createHistory from "history/createBrowserHistory"

import AppRouter from "./Router"

import "antd/dist/antd.min.css"
import "./assets/css/index.css"
import "./assets/css/responsive.css"

const history = createHistory()
history.listen(() => {
    window.scrollTo(0, 0)
})

const render = (Component) => {
    return ReactDOM.render(
        <HashRouter history={history}>
            <Component />
        </HashRouter>,
        document.getElementById("root")
    )
}

render(AppRouter)
