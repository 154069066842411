import React from "react"
import PropTypes from "prop-types"
import {Carousel} from "antd"
import {imgSrc} from "../../modules"

class SliderComponent extends React.Component {
    render() {
        const {images, autoSlider} = this.props
        if (!images) return null
        return (
            <Carousel effect="fade" autoplay={autoSlider ? true : false} autoplaySpeed={3800} draggable>
                {images.map((value, key) => {
                    return (
                        <div key={"slider" + key}>
                            <img src={imgSrc(value.image)} alt={value.name} />
                        </div>
                    )
                })}
            </Carousel>
        )
    }
}

SliderComponent.propTypes = {
    images: PropTypes.array.isRequired,
    autoSlider: PropTypes.bool
}

export default SliderComponent
