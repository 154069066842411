import React from "react"
import Helmet from "react-helmet"
import About from "./components/about"
import Category from "./components/category"
import Slider from "../../components/slider"
import Items from "../../components/item"
import Articles from "../../components/article"
import {get} from "../../modules/axios"

class HomePage extends React.Component {
    state = {data: {}, loading: true}
    componentWillMount = () => {
        get("home.php", {}, (res) => {
            if (res && res.sliders) {
                this.setState({data: res, loading: false})
            }
        })
    }
    render() {
        const {data, loading} = this.state
        if (loading) return null

        return (
            <React.Fragment>
                <Helmet>
                    <title>Mr.2HandStore</title>
                    <meta name="description" content="ร้านขายเสื้อเชิ้ตลายสก็อตมือสองคัดเกรด แขนยาว/แขนสั้น เดรสสั้น มีท้ังเสื้อผู้หญิง,เสื้อผู้ชาย, และเสื้อผ้าเด็ก มีทั้งขายปลีกและขายส่ง เป็นรายชิ้น[ขายปลีก] และยกแพ็ค 50 ตัว[ขายส่ง] เสื้อทุกตัวผ่านการคัดเกรดเรียบร้อยพร้อมขายได้เลย" />
                </Helmet>
                <Slider images={data.sliders} autoSlider={true}/>
                <About about={data.about} />
                <Category categories={data.categories} />
                <Items items={data.products} name="สินค้าใหม่" />
                <Articles items={data.articles} name="บทความน่ารู้" />
            </React.Fragment>
        )
    }
}

export default HomePage
