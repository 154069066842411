import React from "react"
import {Switch} from "react-router-dom"
import {PublicRoute} from "./components/layouts"

import Home from "./pages/home"
import About from "./pages/about"
import Products from "./pages/products"
import Product from "./pages/products/view"
import Payment from "./pages/payment"
import Check from "./pages/check"
import Faqs from "./pages/faqs"
import Articles from "./pages/articles"
import Article from "./pages/articles/view"
import Contact from "./pages/contact"
import Cart from "./pages/cart"
import Confirm from "./pages/confirm"
import Success from "./pages/success"
import Profile from "./pages/profile"
import {post} from "./modules/axios"

class AppRouter extends React.Component {
    state = {info: {}, member: {}, loading: true, thai: []}

    componentWillMount = () => {
        let member = {token: false, name: false, email: false}
        if (window.localStorage.getItem("token")) {
            member.token = window.localStorage.getItem("token")
            member.email = window.localStorage.getItem("email")
        }
        post("index.php", member, (res) => {
            if (res.info) {
                this.setState({info: res.info, loading: false, member: res.member || {token: false, email: false, name: false}, thai: res.thai || []})
            }
        })
    }

    render() {
        const {info, member, thai} = this.state

        const publicRouting = (
            <Switch>
                <PublicRoute path="/about" component={About} info={info} member={member} />
                <PublicRoute path="/products/:id" component={Products} info={info} member={member} />
                <PublicRoute path="/products" component={Products} info={info} member={member} />
                <PublicRoute path="/payment" component={Payment} info={info} member={member} />
                <PublicRoute path="/check" component={Check} info={info} member={member} />
                <PublicRoute path="/faqs" component={Faqs} info={info} member={member} />
                <PublicRoute path="/contact" component={Contact} info={info} member={member} />
                <PublicRoute path="/cart/:id" component={Cart} headerStyle={2} info={info} member={member} />
                <PublicRoute path="/cart" component={Cart} headerStyle={2} info={info} member={member} />
                <PublicRoute path="/product/:id" component={Product} info={info} member={member} />
                <PublicRoute path="/articles" component={Articles} info={info} member={member} />
                <PublicRoute path="/article/:id" component={Article} info={info} member={member} />
                <PublicRoute path="/confirm" component={Confirm} headerStyle={2} info={info} member={member}  thai={thai}/>
                <PublicRoute path="/success" component={Success} headerStyle={2} info={info} member={member} />
                {member.token && <PublicRoute path="/profile" component={Profile} headerStyle={2} info={info} member={member} thai={thai} />}
                <PublicRoute path="/" component={Home} info={info} member={member} />
            </Switch>
        )
        return publicRouting
    }
}

export default AppRouter
