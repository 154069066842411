import React from "react"
import Helmet from "react-helmet"
import {Breadcrumb, Collapse} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import {get} from "../../modules/axios"

const Panel = Collapse.Panel

class FaqsPage extends React.Component {
    state = {faqs: [], loading: true}
    componentWillMount = () => {
        get("faqs.php", {}, (res) => {
            if (res && typeof res === "object") {
                this.setState({loading: false, faqs: res})
            }
        })
    }
    nl2br = (str, is_xhtml) => {
        if (typeof str === "undefined" || str === null) {
            return ""
        }
        var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>"
        return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2")
    }
    render() {
        const {faqs, loading} = this.state
        if (loading) return null
        return (
            <div id="faqs-page" className="page">
                <Helmet>
                    <title>คำถามที่พบบ่อย - Mr.2HandStore</title>
                </Helmet>
                <Heading name="คำถามที่พบบ่อย" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>คำถามที่พบบ่อย</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="container">
                    <Collapse bordered={false} defaultActiveKey={["1"]} accordion={true}>
                        {faqs.map((value, key) => {
                            return (
                                <Panel header={value.question} key={"faq" + key}>
                                    <div class="collapse-detail">
                                        <div dangerouslySetInnerHTML={{__html: this.nl2br(value.answer)}} />
                                    </div>
                                </Panel>
                            )
                        })}
                    </Collapse>
                </div>
            </div>
        )
    }
}

export default FaqsPage
