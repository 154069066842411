import React from "react"
import Helmet from "react-helmet"
import moment from "moment"
import {Breadcrumb, Table, Input} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import {get} from "../../modules/axios"

const Search = Input.Search

const columns = [
    {
        title: "เลขที่ใบสั่งซื้อ",
        dataIndex: "orderId",
        key: "orderId"
    },
    {
        title: "Tranking number",
        dataIndex: "tranking_id",
        key: "tranking_id"
    },
    {
        title: "ประเภทการส่งสินค้า",
        dataIndex: "shipping_type",
        key: "shipping_type",
        filters: [
            {
                text: "ฟรีจัดส่ง!!(ขนส่งเอกชน)",
                value: "register"
            },
            {
                text: "เก็บเงินปลายทาง",
                value: "ems"
            }
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.shipping_type.indexOf(value) === 0,
        render: (shipping_type) => {
            return shipping_type === "ems" ? "เก็บเงินปลายทาง" : "ฟรีจัดส่ง!!(ขนส่งเอกชน)"
        }
    },
    {
        title: "วันที่ส่งสินค้า",
        dataIndex: "shipping_date",
        key: "shipping_date",
        render: (shipping_date) => {
            return moment(shipping_date).format('LL')
        }
    }
]

class CheckStatusPage extends React.Component {
    state = {dataSource: [], search: ""}
    componentDidMount = () => {
        get("check.php", {}, (res) => {
            if (res && typeof res === "object") {
                this.setState({dataSource: res})
            }
        })
    }
    handleSearch = (text) => {
        this.setState({search: text})
    }
    filterPoets = (poetFilter) => {
        let filteredPoets = this.state.poets
        filteredPoets = filteredPoets.filter((poet) => {
            let poetName = poet.firstName.toLowerCase() + poet.lastName.toLowerCase()
            return poetName.indexOf(poetFilter.toLowerCase()) !== -1
        })
        this.setState({
            filteredPoets
        })
    }
    render() {
        const {dataSource, search} = this.state
        let dataFiltered = dataSource
        dataFiltered = dataFiltered.filter((data) => {
            let textGroup = data.name.toLowerCase() + data.orderId.toLowerCase()
            return textGroup.indexOf(search.toLowerCase()) !== -1
        })

        return (
            <div id="check-page" className="page">
                <Helmet>
                    <title>ตรวจสอบสถานะสินค้า - Mr.2HandStore</title>
                </Helmet>
                <Heading name="ตรวจสอบสถานะสินค้า" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>ตรวจสอบสถานะสินค้า</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        <div className="text-center">
                            <div id="search-check">
                                <label>ค้นหา</label>
                                <Search placeholder="ชื่อลูกค้า/เลขที่ใบสั่งซื้อ" onSearch={this.handleSearch} />
                            </div>
                        </div>
                        <Table bordered={true} dataSource={dataFiltered} columns={columns} pagination={{defaultPageSize: 30}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckStatusPage
