import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import {Modal} from "antd"
import $ from "jquery"
import {Link} from "react-router-dom"
import Icon from "./../../icon"
import FormLogin from "../../member/login"
import FormRegister from "../../member/register"
import {imgSrc, getNumber} from "../../../modules"

class HeaderComponent extends React.Component {
    state = {
        cartStyle: "icon-cart-white",
        visibleLogin: false,
        visibleRegister: false,
        menuActive: false,
        itemInCart: 0
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let s = nextProps.history.location.search
        if (s) {
            s = queryString.parse(s)
            if (s.n !== prevState.itemInCart) {
                return {itemInCart: s.n}
            } else return null
        } else {
            return null
        }
    }

    setCart = (style) => {
        this.setState({cartStyle: style})
    }
    handleOk = () => {
        this.setState({visibleLogin: false, visibleRegister: false})
    }
    handleCancel = () => {
        this.setState({visibleLogin: false, visibleRegister: false})
    }
    showLogin = () => {
        this.setState({visibleLogin: true})
    }
    showRegister = () => {
        this.setState({visibleRegister: true})
    }
    showLoginInstread = () => {
        this.setState({visibleRegister: false})
        setTimeout(() => {
            this.setState({visibleLogin: true})
        }, 1000)
    }
    showMenu = () => {
        this.setState({menuActive: true})
    }
    hideMenu = () => {
        this.setState({menuActive: false})
    }
    handleLogout = () => {
        if (window.confirm("Are you sure to logout?")) {
            const cart = window.localStorage.getItem("cart")
            const visited = window.localStorage.getItem("visited")
            setTimeout(() => {
                window.localStorage.clear()
                if (cart) {
                    window.localStorage.setItem("cart", cart)
                }
                if (visited) {
                    window.localStorage.setItem("visited", visited)
                }
                window.location.reload()
            }, 500)
        }
    }
    componentDidMount = () => {
        let cart = window.localStorage.getItem("cart")
        if (cart) {
            cart = JSON.parse(cart)
            if (cart && typeof cart === "object") {
                this.setState({itemInCart: cart.length})
            }
        }
        if ($(window).width() < 768) {
            $("#main-menu li>a").click(() => {
                this.hideMenu()
            })
        }
    }

    render() {
        const {cartStyle, visibleLogin, visibleRegister, menuActive, itemInCart} = this.state
        const {path, headerStyle, history, info, member} = this.props

        return (
            <header id={headerStyle && headerStyle === 2 ? "second-header" : ""} className={menuActive ? "active" : ""}>
                <div id="topnav">
                    <div className="container">
                        <div>
                            <ul>
                                {info.fb && (
                                    <li>
                                        <a href={info.fb_link} target="_blank" rel="noopener noreferrer">
                                            <Icon type="icon-facebook" />
                                        </a>
                                    </li>
                                )}
                                {info.ig && (
                                    <li>
                                        <a href={info.ig_link} target="_blank" rel="noopener noreferrer">
                                            <Icon type="icon-instagram" />
                                        </a>
                                    </li>
                                )}
                                {info.line && (
                                    <li>
                                        <a href={info.line_link} target="_blank" rel="noopener noreferrer">
                                            <Icon type="icon-line" />
                                        </a>
                                    </li>
                                )}
                                {info.tel && (
                                    <li>
                                        <a href={"tel:" + getNumber(info.tel)}>
                                            <Icon type="icon-tel02" />
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <ul>
                                {!member.token && (
                                    <li>
                                        <Link to="#" onClick={this.showLogin}>
                                            <Icon type="icon-bx-log-out" />
                                            <span>เข้าสู่ระบบ</span>
                                        </Link>
                                        <Modal title="สมาชิกเข้าสู่ระบบ" footer={false} visible={visibleLogin} onOk={this.handleOk} onCancel={this.handleCancel}>
                                            <FormLogin history={history} onCancel={this.handleCancel} />
                                        </Modal>
                                    </li>
                                )}
                                {member.token && (
                                    <React.Fragment>
                                        <li id="member-logout-menu">
                                            <Link to="#" onClick={this.handleLogout}>
                                                <Icon type="icon-bx-log-out" />
                                                <span>ออกจากระบบ</span>
                                            </Link>
                                        </li>
                                        <li id="member-profile-menu">
                                            <Link to="/profile" onClick={this.showRegister}>
                                                <Icon type="icon-user" />
                                                <span>{member.name}</span>
                                            </Link>
                                        </li>
                                    </React.Fragment>
                                )}
                                {!member.token && (
                                    <li>
                                        <Link to="#" onClick={this.showRegister}>
                                            <Icon type="icon-user" />
                                            <span>สมัครสมาชิก</span>
                                        </Link>

                                        <Modal
                                            title={
                                                <p>
                                                    สมัครสมาชิกเว็บไซต์
                                                    <span>
                                                        ท่านที่เป็นสมาชิกแล้ว สามารถเข้าสู่ระบบได้ที่นี่
                                                        <button type="button" onClick={this.showLoginInstread}>
                                                            เข้าสู่ระบบ
                                                        </button>
                                                    </span>
                                                </p>
                                            }
                                            footer={false}
                                            visible={visibleRegister}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}>
                                            <FormRegister />
                                        </Modal>
                                    </li>
                                )}
                                <li>
                                    <Link to="/cart">
                                        <Icon type={cartStyle} />
                                        {itemInCart > 0 && <span id="cart-balloon">{itemInCart}</span>}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="logo">
                    <img src={imgSrc(info.logo)} alt={info.name} />
                    <button type="button" id="responsive-menu" onClick={this.showMenu}>
                        <span>MENU</span>
                        <Icon type="icon-bars" />
                    </button>
                </div>
                <nav>
                    <div className="container">
                        <ul id="main-menu">
                            <li className={path === "/" ? "active" : ""} id="nav-logo">
                                <Link to="/">
                                    <img src="/img/logo.png" alt="Mr. 2 Hand Store" />
                                </Link>
                            </li>
                            <li className={path === "/" ? "active" : ""}>
                                <Link to="/">หน้าแรก</Link>
                            </li>
                            <li className={path === "/about" ? "active" : ""}>
                                <Link to="/about">เกี่ยวกับเรา</Link>
                            </li>
                            <li className={path === "/products" ? "active" : ""}>
                                <Link to="/products">สินค้า</Link>
                            </li>
                            <li className={path === "/payment" ? "active" : ""}>
                                <Link to="/payment">วิธีสั่งซื้อ/แจ้งชำระเงิน</Link>
                            </li>
                            <li className={path === "/check" ? "active" : ""}>
                                <Link to="/check">ตรวจสอบสถานะสินค้า</Link>
                            </li>
                            <li className={path === "/faqs" ? "active" : ""}>
                                <Link to="/faqs">คำถามที่พบบ่อย</Link>
                            </li>
                            <li className={path === "/articles" ? "active" : ""}>
                                <Link to="/articles">บทความน่าสนใจ</Link>
                            </li>
                            <li className={path === "/contact" ? "active" : ""}>
                                <Link to="/contact">ติดต่อเรา</Link>
                            </li>
                        </ul>
                    </div>
                    <button type="button" id="responsive-menu-close" onClick={this.hideMenu}>
                        <Icon type="icon-LC_icon_close_fill_circle" />
                    </button>
                </nav>
            </header>
        )
    }
}

HeaderComponent.propTypes = {
    info: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired
}

export default HeaderComponent
