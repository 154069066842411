import React from "react"
import {Form, Icon, Input, Button, message} from "antd"
import {rules} from "../../modules"
import {post} from "../../modules/axios"

class RegisterComponent extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault()
        const {form} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                if (values.password !== values.repassword) {
                    message.warning("Password and confirm password does not match")
                    return null
                }
                post("register.php", values, (res) => {
                    if (res && res.status === "1") {
                        form.resetFields()
                        window.localStorage.setItem("token", res.data.token)
                        window.localStorage.setItem("email", res.data.email)
                        window.localStorage.setItem("name", res.data.name)
                        message.success(res.message)
                        setTimeout(() => {
                            window.location = "/#/profile"
                            window.location.reload();
                        }, 1000)
                    } else if (res && res.status === "0") {
                        message.error(res.message)
                    }
                })
            }
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        return (
            <div id="register">
                <Form onSubmit={this.handleSubmit}>
                    <div>
                        <Form.Item label="ชื่อ-สกุล">{getFieldDecorator("name", rules.text)(<Input type="text" prefix={<Icon type="user" />} placeholder="Your name" maxLength={100} />)}</Form.Item>
                        <Form.Item label="อีเมล">{getFieldDecorator("email", rules.email)(<Input type="email" prefix={<Icon type="mail" />} placeholder="Email" maxLength={100} />)}</Form.Item>
                        <Form.Item label="รหัสผ่าน">{getFieldDecorator("password", rules.password)(<Input prefix={<Icon type="lock" />} minLength={4} maxLength={15} type="password" placeholder="Password" />)}</Form.Item>
                        <Form.Item label="รหัสผ่านอีกครั้ง">{getFieldDecorator("repassword", rules.password)(<Input prefix={<Icon type="lock" />} type="password" minLength={4} maxLength={15} placeholder="Password" />)}</Form.Item>
                        <div className="wrap-register">
                            <Button type="primary" htmlType="submit">
                                <span>สมัครสมาชิก</span>
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Form.create({name: "register"})(RegisterComponent)
