import React from "react"
import PropTypes from "prop-types"
import {Icon} from "antd"

class IconComponent extends React.Component {
    render() {
        const {type, classNames} = this.props
        const MyIcon = Icon.createFromIconfontCN({
            scriptUrl: "//at.alicdn.com/t/font_1131661_crmc79eekjw.js"
        })
        return <MyIcon type={type} className={classNames} />
    }
}

IconComponent.propTypes = {
    type: PropTypes.string.isRequired,
    classNames: PropTypes.string
}

export default IconComponent
