import React from "react"
import Helmet from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"
import {Breadcrumb, Form, Button, Input, message} from "antd"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import {imgSrc, rules, getNumber, recaptchaKey} from "../../modules"
import {post} from "../../modules/axios"

const paymentRecapcha = React.createRef()

class ContactPage extends React.Component {
    state = {recapcha: false, loading: false}
    handleSubmit = (e) => {
        e.preventDefault()
        const {recapcha} = this.state
        const {form} = this.props
        form.validateFields((err, values) => {
            if (recapcha) {
                this.setState({loading: true})
                post("contact.php", {...values, recapcha}, (res) => {
                    this.setState({loading: false})
                    if (res && typeof res === "object") {
                        if (res.status === "1") {
                            message.success(res.message, 10)
                            form.resetFields()
                        } else {
                            message.warning(res.message)
                        }
                    } else {
                        message.error("Error !")
                    }
                    paymentRecapcha.current.reset()
                })
            } else {
                message.warning("Please check reCAPTCHA")
            }
        })
    }
    onChange = (value) => {
        this.setState({recapcha: value})
    }

    render() {
        const {loading} = this.state
        const {form, info} = this.props
        const {getFieldDecorator} = form
        if (!info) return null

        return (
            <div id="contact-page" className="page">
                <Helmet>
                    <title>ติดต่อเรา - Mr.2HandStore</title>
                    <meta name="description" content="Mr.2HandStore, 142/9 ถ.ประชาสัมพันธ์ ต.ช้างคลาน อ.เมือง จ.เชียงใหม่ 50100 โทร.062-887-8987 Line ID/IG : Mr.2HandStore" />
                </Helmet>
                <Heading name="ติดต่อเรา" />
                <div className="container">
                    <Breadcrumb separator={<Icon type="icon-angleright" />}>
                        <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
                        <Breadcrumb.Item>ติดต่อเรา</Breadcrumb.Item>
                    </Breadcrumb>

                    <div id="wrap-contact">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="ชื่อ-สกุล">{getFieldDecorator("name", rules.text)(<Input />)}</Form.Item>
                            <Form.Item label="อีเมล">{getFieldDecorator("email", rules.email)(<Input />)}</Form.Item>
                            <Form.Item label="เบอร์โทรศัพท์">{getFieldDecorator("tel", rules.text)(<Input />)}</Form.Item>
                            <Form.Item label="ข้อความ">{getFieldDecorator("message", rules.text)(<Input.TextArea />)}</Form.Item>
                            <Form.Item label="reCAPTCHA">
                                <ReCAPTCHA ref={paymentRecapcha} sitekey={recaptchaKey()} onChange={this.onChange} />
                                {!loading ? (
                                    <div className="wrap-submit">
                                        <Button type="primary" htmlType="submit">
                                            <span>ส่งข้อความ</span>
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="wrap-submit">
                                        <Button type="primary" htmlType="button" >
                                            <span>กรุณารอสักครู่...</span>
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        </Form>
                        <div>
                            <h2>{info.description}</h2>
                            <p>ที่อยู่ : {info.address}</p>
                            <p className="tel">
                                โทรศัพท์ : <a href={"tel:" + getNumber(info.tel)}>{info.tel}</a>
                            </p>
                            <ul>
                                <li>
                                    <img src={imgSrc("line.png", false)} alt="line" />
                                    <p>
                                        <span>
                                            Line ID :
                                            <a href={info.line_link} rel="noopener noreferrer" target="_blank">
                                                {info.line}
                                            </a>
                                        </span>
                                        <span>
                                            Line QR :
                                            <a href={info.line_link} rel="noopener noreferrer" target="_blank">
                                                {info.line_link}
                                            </a>
                                        </span>
                                    </p>
                                    <img src={imgSrc("fb.png", false)} alt="Facebook" />
                                    <p>
                                        <span>Facebook :</span>
                                        <a href={info.fb_link} rel="noopener noreferrer" target="_blank">
                                            {info.fb}
                                        </a>
                                    </p>
                                    <img src={imgSrc("ig.png", false)} alt="Instagram" />
                                    <p>
                                        <span>Instagram :</span>
                                        <a href={info.ig_link} rel="noopener noreferrer" target="_blank">
                                            {info.ig}
                                        </a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({name: "contact"})(ContactPage)
