import React from "react"
import PropTypes from "prop-types"

class HeadingComponent extends React.Component {
    render() {
        const {name} = this.props
        return (
            <div id="heading-page">
                <h1>{name}</h1>
            </div>
        )
    }
}

HeadingComponent.propTypes = {
    name: PropTypes.string.isRequired
}

export default HeadingComponent
