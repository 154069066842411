import React from "react"
import {Link} from "react-router-dom"
import moment from "moment"
import PropTypes from "prop-types"
import {imgSrc} from "../../modules"

class ItemComponent extends React.Component {
    render() {
        const {item} = this.props
        return (
            <div className="item">
                <div>
                    <img src={imgSrc(item.image)} alt={item.image} />
                    <div>
                        <h3>
                            <Link to={"/article/" + item.id}>{item.name}</Link>
                        </h3>
                        <p className="date">{moment(item.dates).format("LL")}</p>
                        <p className="description">{item.description}</p>
                        <div>
                            <Link to={"/article/" + item.id} className="view">
                                <span>อ่านเพิ่มเติม</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ItemComponent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        dates: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string
    }).isRequired
}

export default ItemComponent
