import React from "react"
import Helmet from "react-helmet"
import {Table} from "antd"
import numeral from "numeral"
import Icon from "../../components/icon"
import {imgSrc} from "../../modules"
import {get} from "../../modules/axios"
import ButtonLink from "../../components/button"

const columns = [
    {
        title: "สินค้า",
        dataIndex: "code",
        key: "code",
        render: (code, record) => {
            return (
                <div className="item-detail">
                    <img src={imgSrc(record.image)} alt={code} />
                    <div>
                        <h3>{record.code}</h3>
                        <p>Size : {record.size}</p>
                        <p>{record.size_description}</p>
                        <p>Brand : {record.brand}</p>
                    </div>
                </div>
            )
        }
    },
    {
        title: "ราคา",
        dataIndex: "price",
        key: "price",
        render: (price) => {
            return <span>฿ {numeral(price).format("0,0")}</span>
        }
    },
    {
        title: "จำนวน",
        dataIndex: "quantity",
        key: "quantity",
        render: (quantity) => {
            return quantity
        }
    },
    {
        title: "ราคารวม",
        dataIndex: "price",
        key: "net",
        render: (price, record) => {
            return <div>฿ {numeral(price * record.quantity).format("0,0")}</div>
        }
    }
]

class SuccessPage extends React.Component {
    state = {data: {}}
    componentDidMount = () => {
        get("confirm.php", {}, (res) => {
            if (res && res.id) {
                this.setState({data: res})
            }
        })
        window.localStorage.removeItem("cart")
    }
    nl2br = (str, is_xhtml) => {
        if (typeof str === "undefined" || str === null) {
            return ""
        }
        var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>"
        return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2")
    }
    render() {
        const {data} = this.state
        let transaction = window.localStorage.getItem("transaction")
        let transaction_list = window.localStorage.getItem("transaction_list")
        if (transaction) {
            transaction = JSON.parse(transaction)
        }
        if (!transaction) return null
        if (transaction_list) {
            transaction_list = JSON.parse(transaction_list)
        }
        if (!transaction_list) return null
        return (
            <div id="success-page" className="page">
                <Helmet>
                    <title>สั่งซื้อเรียบร้อย - Mr.2HandStore</title>
                </Helmet>
                <div className="container">
                    <h1>สั่งซื้อเรียบร้อย</h1>
                    <div>
                        <div id="thank">
                            <Icon type="icon-check2" />
                            <div>
                                <h2>ขอบคุณที่สั่งซื้อสินค้า</h2>
                                <p>ขอแสดงความยินดี! คำสั่งซื้อของคุณสำเร็จแล้ว</p>
                            </div>
                        </div>
                        <div id="shipping-detail">
                            <div>
                                <h3>ข้อมูลการจัดส่งสินค้า</h3>
                                <p>
                                    <span>ชื่อ-สกุล :</span> {transaction.name}
                                </p>
                                <p>
                                    <span>ที่อยู่ :</span> {transaction.address}
                                </p>
                                <p>
                                    <span>โทรศัพท์ :</span> {transaction.tel}
                                </p>
                                <p>
                                    <span>อีเมล :</span> {transaction.email}
                                </p>
                            </div>
                            <div>
                                <h3>รายละเอียดการสั่งซื้อสินค้า</h3>
                                <p>
                                    <span>วันที่สั่งซื้อ :</span> {transaction.created_date}
                                </p>
                                <p>
                                    <span>เลขที่ใบสั่งซื้อ :</span> {transaction.orderId}
                                </p>
                            </div>
                            <div id="shipping-info">
                                <h3>การจัดส่งสินค้า</h3>
                                <div>
                                    <ul>
                                        <li>
                                            <b>สินค้าขายปลีก : จัดส่งโดยขนส่งเอกชน (เก็บเงินปลายทาง) ({transaction.shipping_type})</b>
                                        </li>
                                    </ul>
                                    <p>
                                        <b>กรณีที่ลูกค้าสั่งซื้อสินค้าแบบขายส่ง</b>
                                        <br /> เราจะส่งสินค้าแบบขายส่งแยก โดยจัดส่งผ่านบริการ Nim Express
                                    </p>
                                </div>
                            </div>
                            <div>
                                <h3>วิธีการชำระเงิน</h3>
                                <div dangerouslySetInnerHTML={{__html: data.payment_detail}} />
                            </div>
                        </div>
                        <div id="summary">
                            <h3>รายการสั่งซื้อ</h3>
                            <Table dataSource={transaction_list} columns={columns} pagination={false} rowKey={(rec) => rec.id} />
                            <div>
                                <p>
                                    <span>ยอดรวม</span>
                                    <span>฿ {numeral(transaction.total).format("0,0.00")}</span>
                                </p>
                                <p>
                                    <span>ค่าจัดส่ง</span>
                                    <span>฿ {numeral(transaction.shipping_price).format("0,0.00")}</span>
                                </p>
                                <p>
                                    <span>ยอดสุทธิ</span>
                                    <span>฿ {numeral(transaction.net).format("0,0.00")}</span>
                                </p>
                            </div>
                        </div>
                        <div id="note">
                            <p>จัดส่งสินค้าถึงขนส่งภายใน 1-2 วันทำการ นับจากวันที่ลูกค้าแจ้งโอนยอดรายการเสร็จสิ้น</p>
                            <p>ราคาสินค้ายังไม่รวมภาษีมูลค่าเพิ่ม (Vat 7%)</p>
                        </div>
                        <div id="wrap-button">
                            <ButtonLink to={"/payment?id=" + transaction.orderId} className="primary">
                                แจ้งชำระเงิน
                            </ButtonLink>
                            <button type="button" className="primary" onClick={window.print}>
                                <Icon type="icon-print" />
                                <span>พิมพ์ใบรายการสั่งซื้อ</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SuccessPage
